<template>
    <div class="page-wrapper" id="main">
      <v-card  v-if="dataLoaded==true" :disabled="saving || taskSaved || taskInfo.completed"
        v-bind:style="taskSaved?'background:#c5c5c5' : ''">
        <v-card-title style="word-wrap: break-word;">{{taskInfo.task}} <br>№ {{ taskInfo.docnum }}</v-card-title>

        <v-card-text>
            <v-row v-if="taskInfo.completed" class="sumauto" style="color: green;" >
                 Задача выполнена
            </v-row>  
            <v-row>
              <v-col class="sumauto">
                 {{taskInfo.ts}}
              </v-col>             
            </v-row>  
            <v-row>
               <div v-html="taskInfo.description"></div>
            </v-row>  
            <v-row v-if="false">
               {{taskInfo.damage}}
            </v-row>  
            <v-row>
              <v-col class="sumauto">
                 {{taskInfo.action}}
              </v-col>             
            </v-row>  

            <v-row class=""
               v-for="field_element in taskInfo.fields"
                :key="field_element">
                
                <v-switch v-if="field_element.type == 'bool'"
                  class="ml-5 block" color="indigo"
                  v-model = "field_element.value" 
                  :label="field_element.fieldname"
                ></v-switch>

                <v-text-field v-if="field_element.type == 'number'"
                  type="number" pattern="[0-9]*"
                  v-model = "field_element.value" :label="field_element.fieldname" variant="outlined"
                ></v-text-field>
                
                <v-textarea v-if="field_element.type == 'text'"
                 v-model = "field_element.value"  :label="field_element.fieldname" variant="outlined"
                 class="ml-5 block"></v-textarea>

                <v-text-field v-if="field_element.type == 'date'"
                  type="date" 
                  v-model = "field_element.value"  :label="field_element.fieldname" variant="outlined"
                  locale = "ru-ru"
                  ></v-text-field>

                <v-select v-if="field_element.type == 'list'"
                  v-model = "field_element.value"
                  :label="field_element.fieldname"
                  :items="field_element.values"
                  item-title="name"
                  item-value="code">
                </v-select>


         </v-row>

        </v-card-text>
        <v-card-actions v-show="!taskSaved">
          <v-btn v-if="false"
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveTask()"
            >Сохранить и закрыть
          </v-btn>
          <v-btn
            class="mx-1"
            small
            outlined
            color="success"
            @click="saveTask(true)"
            >Выполнить задачу
          </v-btn>
          <v-btn
            class="mx-1"
            small
            @click="closeForm()"
            >Отмена
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-progress-linear  v-show="(saving==true)"
        color="dark-blue" indeterminate></v-progress-linear> 
      <v-row style="height: 300px; color: white;" >
        <div>.</div>
      </v-row>  
    </div>
  </template>
  
  <script>
  //   :rules= "[rules.max(sum_element.sum,sum_element.sum_opl)]"
  
 import ArendaService from '@/api/arenda-service'
 import { mapGetters, mapState } from "vuex"

  export default {
    components: {  },
    props: {
      taskid: String
    },
    data: function() {
      return {
        taskInfo: {},
        comment: '',
        }
    },
    computed: {
      ...mapGetters('doc',['task']),
      ...mapState('doc',['dataLoaded','menu_visible','taskSaved','saving'])
    },
    created: async function() {

        var userid = ''
        try { 
           userid = this.TWA.initDataUnsafe.user.id
        } catch(err) {
          userid = '128283566'
        }
        const response = await ArendaService.getTask(this.taskid, userid)
        this.taskInfo = response
        console.log(' task: created ',JSON.stringify(response))
        this.$store.commit('doc/setShowMenu', false)
        this.$store.commit('doc/dataLoaded', true)
    },
    mounted: function() {
      this.obj = {}
      console.log(' Task: mounted')
    },
    methods: {
      async closeForm() {  
        console.log('form closed')   
        //this.$store.commit('doc/setMode', 'docinfo')
        //this.TWA.close()
      },
      toggleType() {
        console.log('toggle')
      },

      async saveTask(completed = false) {

        if (this.taskInfo.fields.some(field => field.fieldname === "Способ возмещения" && !field.value)){
          this.TWA.showAlert('Обязателен выбор способа возмещения')
          return
        }

        const data = {
            id: this.taskid,
            ...this.taskInfo,
            completed: completed
          }
        try { 
          data.tg = this.TWA.initDataUnsafe
          data.user = this.TWA.initDataUnsafe.user.username
          data.userid = this.TWA.initDataUnsafe.user.id
        } catch(err) { 
          console.log('no tg')
          data.tg = {user:{id:'128283566'} }
          data.user = 'olegkrt'
          data.userid = '128283566'
        }
  
        console.log('save task',JSON.stringify(data) )
        this.$store.commit('doc/setSaving', true) 
        const response = await ArendaService.saveTask(this.taskid,data)
        console.log(JSON.stringify(response) )
        if (response.data.result == false) {
          this.$store.commit('doc/setError', 'Ошибка при сохранении задачи') 
          this.$store.commit('doc/setSaved', false) 
          return  
        }
        console.log('task saved' )

        this.$store.commit('doc/setSaved', true) 
        this.TWA.showAlert('Задача сохранена')
        this.taskInfo.completed = completed
        this.$store.commit('doc/dataLoaded', true)
      },
      }
    }
  
  </script>
  
  <style scoped>
/*
bg_color            .
secondary_bg_color  var(--tg-theme-secondary-bg-color)
link_color          var(--tg-theme-link-color).
*/
#main {
  background-color: var(--tg-theme-bg-color, white);
  color: var(--tg-theme-text-color, black);
  word-wrap: break-word;
}
b {
  color: var(--tg-theme-hint-color, black);
}
h3 {
  color: var(--tg-theme-text-color, black);
}
.sumauto {
  font-size: 15px;
  color: #280380; 
  font-weight: 600;
}

</style>