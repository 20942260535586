<template>
  <v-app>
    <v-main>
      <div class="text-center"  v-if="(dataLoaded==false || saving==true) && isError==false">
            <v-progress-circular indeterminate :size="70">
            </v-progress-circular>
        </div>
        <div class="text-center"  v-if="isError==true">
          <v-alert
            title="Внимание" :text="ErrorText"
            color="error"
            icon="$error"
            variant="tonal">
          </v-alert>
          <v-btn v-if="false"
              class="ma-3"
              @click="closeForm()"
              >Закрыть
          </v-btn>
        </div>      
    <newRKO 
      v-if="command=='rko'"></newRKO>
    <task 
      v-if="command=='task'"
      v-bind:taskid="vars.taskid"></task>
    <report 
      v-if="command=='report'"></report>
    <requests 
      v-if="command=='requests'"></requests>
    <innerDocs 
      v-if="command=='inners'"
      v-bind:arendaid="vars.id"></innerDocs>
    <div v-if="command != 'rko' && command != 'report' && command !='requests'  && command !='inners'">
    <arenda 
        v-if ="vars.id"
        v-bind:arendaid="vars.id" ></arenda> 
     <div v-if="!vars.id && !vars.taskid">
          не выбран документ
        <v-row>
          <v-btn class="ma-3"
                  @click="closeForm()"
                  >Закрыть форму
          </v-btn>
        </v-row>
      </div> 
    </div>
  </v-main>

  <div color="lightgrey"> user: {{ username }}</div>      
  </v-app>
</template>

<script>
import arenda from '@/views/form_Arenda.vue'
import newRKO from '@/views/form_NewRKO_other.vue'
import report from '@/views/formReport.vue'
import requests from '@/views/form_RequestsList.vue'
import innerDocs from '@/views/form_InnerList.vue'
import task from '@/views/form_Task.vue'
import { mapGetters,mapState } from "vuex"


export default {
  name: 'App',
  components: {arenda, newRKO, report, requests, innerDocs, task},
  props: {
    status: String,
  },
  computed: {
      ...mapGetters('doc',['dataLoaded','saving','command']),
      ...mapState('doc',['isError','ErrorText']),

    },
  data: function() {
      return {
        vars: {},
        username: ''
      }
    },
  created: async function() {
   // this.status = 'Active'
    console.log('App created')
    try {
        this.username = this.TWA.initDataUnsafe.user.username
        //this.$store.commit('doc/setTGName',this.TWA.initDataUnsafe.user.username)
        //this.$store.commit('doc/setTGid',this.TWA.initDataUnsafe.user.id)
        this.$store.commit('doc/setTG',this.TWA.initDataUnsafe)
      } catch(err) {
        console.log('error '+err.message)
        this.$store.commit('doc/setTG',{user:{id:'128283566', username:'notg'}})
    }     

    let uri = window.location.href.split('?');
    if(uri.length == 2) {
      let vars = uri[1].split('&');
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v) {
        tmp = v.split('=');
        if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
      });
      console.log('url',getVars);
      this.vars = getVars
      this.$store.commit('doc/setMode',getVars['op'])
      this.$store.commit('doc/setArendaid',getVars['id'])
      this.$store.commit('doc/setTaskid',getVars['taskid'])
      this.$store.commit('doc/setUrlvars',getVars)  
    }
  },
  mounted: function() {
    console.log(' App: mounted ')
    //this.path = this.$route.fullPath
  },
  methods: {
   closeForm() {  
        this.TWA.close()
      },

  }

};
</script>

<style>
</style>